import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

const NotFound = () => {
  const styling = {
    width: "100%",
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "33px"
  };

  return (
    <div>
      <Header />
      <ScrollToTop />
      <div style={styling}>
        Här var det tomt ¯\_(ツ)_/¯
      </div>
      <Footer />
    </div>
  )
};

export default NotFound;
