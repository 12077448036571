import './BoxRow.css';

const BoxRow = props => {
  return (
    <div className="BoxRow-boxes">
      <div className="BoxRow-row">
        {props.children}
      </div>
    </div>
  )
};

export default BoxRow;
