import "./Contact.css";
import HeadLine from '../../components/HeadLine/HeadLine';
import BackArrow from '../../components/BackArrow/BackArrow';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

import contact_img from "../../assets/contact_img.png";

function Contact() {
  return (
    <div>
      <Header />
      <ScrollToTop />

      <div className="Contact-main">
        <HeadLine page="Kontakt" bg="#FFB1A3" />

        <div className="Contact-content">
          Har du frågor eller funderingar,<br />kontakta oss!
        </div>

        <img id="Contact-img" src={contact_img} alt="Bild på Stefan Nilszén" />

        <div className="Contact-content">
          Stefan Nilszén<br />
          <div id="Contact-info-text">
            Fil. kand. i historia, religion och statskunskap. <br />
            Lärare och projektledare.
          </div>

          <br />

          <div>
            <a id="Contact-contact-me" href="mailto:stefan.nilszen@gmail.com">stefan.nilszen@gmail.com</a>
            <br />
            <a id="Contact-info-text" href="tel:070-667 88 55">070-667 88 55</a>
          </div>

          <br />
          <br />

          <div id="Contact-info-text">
            Nilszén Form & Design AB.
            <br />
            Org. nr. 559336-3277
          </div>
        </div>

        <BackArrow />
      </div>

      <Footer />
    </div>

  );
}

export default Contact;
