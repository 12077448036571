import { Link } from 'react-router-dom';
import './Box.css';

const Box = props => {
  return (
    <div className="Box-row" style={props.style}>
      <Link to={props.to}>
        <div className="Box-on-hover"></div>
        <div className="Box-text">
          <p>{props.text}</p>
        </div>
      </Link>
    </div>
  )
};

export default Box;
