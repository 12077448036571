import React from 'react';
import { Link } from "react-router-dom";

import { getDatabase, ref, child, get } from "firebase/database";

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = { v: "loading...", uv: "loading..." };

    const dbRef = ref(getDatabase());
    get(child(dbRef, "ips")).then((snapshot) => {
      let visitors = 0;
      let uniqueVisitors = 0;

      snapshot.forEach(child => {
        uniqueVisitors++;
        visitors += child.val()["visits"] + 1;
      });

      this.setState({
        v: visitors,
        uv: uniqueVisitors
      });
    }).catch((error) => { console.log(error); });
  }

  render() {
    return (
      <div>
        <div>
          <p>Besökare: {this.state.v}</p>
          <p>Unika besökare: {this.state.uv}</p>
        </div>

        <Link to='/'>Home</Link>
      </div>
    );
  };
};

export default Info;
