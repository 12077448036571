import './References.css';
import Headline from '../../components/HeadLine/HeadLine';
import BackArrow from '../../components/BackArrow/BackArrow';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

import ref1 from "../../assets/references/ref1.png";
import ref2 from "../../assets/references/ref2.png";
import ref3 from "../../assets/references/ref3.png";
import ref4 from "../../assets/references/ref4.png";
import ref5 from "../../assets/references/ref5.png";
import ref6 from "../../assets/references/ref6.png";
import ref7 from "../../assets/references/ref7.png";

const References = () => {
  return (
    <div>
      <Header />
      <ScrollToTop />

      <div>
        <Headline page="Referenser" bg="#DCEED1" />

        <img src={ref7} alt="Sörby Säteri" width="400" className="References-imgs" />
        <img src={ref5} alt="Jultomtarne 150 år" width="400" className="References-imgs" />
        <img src={ref4} alt="Logen nr 6 Holmia 125 år" width="400" className="References-imgs" />
        <img src={ref3} alt="Maj-britt minns Kustorp" width="400" className="References-imgs" />
        <img src={ref2} alt="Varola Missionsförsamling" width="400" className="References-imgs" />
        <img src={ref1} alt="Lanthandeln i hjärtat" width="400" className="References-imgs" />
        <img src={ref6} alt="Televerket och Telia" width="400" className="References-imgs" />

        <br />
        <br />

        <BackArrow />
      </div>

      <Footer />
    </div>
  )
};

export default References;
