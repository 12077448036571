import './MiddleTabs.css';
import BackArrow from '../../components/BackArrow/BackArrow';
import HeadLine from '../../components/HeadLine/HeadLine';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

const Text = () => {
  return (
    <div>
      <Header />
      <ScrollToTop />

      <div style={{ background: "#B8B8B8", color: "white" }}>
        <HeadLine page="Texten" bg="#B8B8B8" />

        <div className="MiddleTabs-content">
          <p>Texten skapar en upplevelse, ger en känsla och kan i bästa fall få läsaren att vilja vara med då det hände.</p>
          <p>
            Texten kan bestå av rubrik, ingress, brödtext och bildtext.
            Hur texten ser ut beror på viket typsnitt man väljer. Ofta väljer man ett typsnitt med "klackar" i långa texter. Times New Roman är ett sådant. I rubriker kan man välja typsnitt mer fritt.
          </p>
        </div>

        <BackArrow />
      </div>

      <Footer />
    </div>
  )
};

export default Text;
