import './BackArrow.css';
import { Link } from "react-router-dom";

const Text = () => {
  return (
    <div id="BackArrow-holder">
      <Link id="BackArrow-a" to='/'>
        <p id="BackArrow-back">Hem</p>
      </Link>
    </div>
  );
};

export default Text;
