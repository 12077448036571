import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './index.css';

import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Book from './pages/Book/Book';
import References from './pages/References/References';
import Intro from './pages/MiddleTabs/Intro';
import Photo from './pages/MiddleTabs/Photo';
import Text from './pages/MiddleTabs/Text';
import Info from './pages/Info/Info';
import NotFound from './pages/NotFound/NotFound';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, set, get } from "firebase/database";


const firebaseConfig = {
  apiKey: "AIzaSyBLqdxgv1s7GFOT1rQsk2A3z5I2tdaBGzI",
  authDomain: "nilszenformdesign-se.firebaseapp.com",
  databaseURL: "https://nilszenformdesign-se-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "nilszenformdesign-se",
  storageBucket: "nilszenformdesign-se.appspot.com",
  messagingSenderId: "485245665131",
  appId: "1:485245665131:web:a2e53ab0a081b98623d62e",
  measurementId: "G-DPBXNK4M31"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

fetch("https://api.ipify.org/?format=json").then((response) => {
  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Something went wrong');
  }
}).then((data) => {
  let ip = data.ip.toString();

  // replace dot with dash
  ip = ip.replace(/\./g, '-');

  let visits = 1;

  const dbRef = ref(getDatabase());
  get(child(dbRef, `ips/${ip}`)).then((snapshot) => {
    if (snapshot.exists()) {
      visits = snapshot.val()["visits"] + 1;
    } else {
      visits = 1;
    }

    set(ref(database, 'ips/' + ip), {
      visits: visits
    });
  }).catch((error) => {
    console.error(error);
  });
}).catch((error) => {
  console.log(error)
});





ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/book" element={<Book />} />
      <Route path="/references" element={<References />} />
      <Route path="/intro" element={<Intro />} />
      <Route path="/photo" element={<Photo />} />
      <Route path="/text" element={<Text />} />
      <Route path="/info" element={<Info />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);