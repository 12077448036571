import './MiddleTabs.css';
import BackArrow from '../../components/BackArrow/BackArrow';
import HeadLine from '../../components/HeadLine/HeadLine';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

const Intro = () => {
  return (
    <div>
      <Header />
      <ScrollToTop />

      <div>
        <HeadLine page="Strukturen" bg="#AA95D0" />

        <div className="MiddleTabs-content">
          <p>Strukturen kräver eftertanke. En roman har en löpande text och kanske en kapitelindelning. I vår typ av bok är kapitel och avsnitt avgörande för läsupplevelsen.</p>
          <p>Tanken är att läsaren ska kunna stanna till var som helst i boken, se en rubrik, titta på bilderna, läsa bildtexterna och så småningom även brödtexten.</p>
          <p>Läsaren förväntas komma tillbaka till boken flera gånger och upptäcka något nytt varje gång.</p>
        </div>

        <BackArrow />
      </div>

      <Footer />
    </div>
  )
};

export default Intro;
