import './MiddleTabs.css';
import BackArrow from '../../components/BackArrow/BackArrow';
import HeadLine from '../../components/HeadLine/HeadLine';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

const Photo = () => {
  return (
    <div>
      <Header />
      <ScrollToTop />

      <div>
        <HeadLine page="Bilden" bg="#C5E2EE" />

        <div className="MiddleTabs-content">
          <p><i>"En bild säger mer än tusen ord"</i> säger vi ofta. Bilden, ett foto, en teckning, ett konstverk eller en grafisk form är mer än ett komplement till texten.</p>
          <p>Bilden ger en annan upplevelse för läsaren än texten. Båda behövs för att skapa en bra läsupplevelse.</p>
          <p>Man ser först bilden, sedan texten. Val av bild är därför viktigt. Den kan vara svartvit eller i färg. Den kan vara begränsad eller utfallande, var ljus eller mörk.</p>
        </div>

        <BackArrow />
      </div>

      <Footer />
    </div>
  )
};

export default Photo;
