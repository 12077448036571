import BoxRow from '../../components/BoxRow/BoxRow';
import Box from '../../components/Box/Box';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import './Home.css';

const Home = () => {
  document.body.style.backgroundColor = "white";

  return (
    <div>
      <Header />
      <div id="Home-landing-page">
        <div id="Home-landing-page-content">
          <div id="Home-landing-page-content-title">
            <p>Funderar du på att göra en bok av din historia?</p>
          </div>

          <div id="Home-landing-page-content-text">
            <p>Då kan vi hjälpa dig med rätt form, design, text och tryck!
              Bra text, intressanta foton och en genomtänkt struktur gör din historia läsvärd!
              <br />
              Ring gärna på <a href="tel:070-667 88 55">070-667 88 55</a>
              </p>
          </div>
        </div>
      </div>


      <div className="Home-text">
        <div class="Home-marquee">
          <p>Det första jag ser i en bok är bilderna, sedan tittar jag på rubrikerna...</p>
        </div>
      </div>

      <BoxRow>
        <Box to="/text" text="Texten"
          style={{ background: "#B8B8B8" }} />

        <Box to="/photo" text="Bilden"
          style={{ background: "#C5E2EE" }} />

        <Box to="/intro" text="Strukturen"
          style={{ background: "#AB96D2" }} />
      </BoxRow>

      <BoxRow>
        <Box to="/book" text="Exempelbok"
          style={{ background: "#f9d1af" }} />

        <Box to="/references" text="Referenser"
          style={{ background: "#DCEED1" }} />

        <Box to="/contact" text="Kontakt"
          style={{ background: "#ffb1a3" }} />
      </BoxRow>

      <Footer />
    </div>
  )
};

export default Home;
