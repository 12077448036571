import './Footer.css';

import logo from "../../assets/logo.png";

const Home = () => (
  <div className="Footer-footer">
    <img id="Footer-logo-img" src={logo} alt="Logo för företaget NILSZÉN FORM OCH DESIGN." />
    <p id="Footer-p">Stefan Nilszén &copy; 2021</p>
  </div>
);

export default Home;
