import './HeadLine.css';

const HeadLine = props => {
  document.body.style.backgroundColor = props.bg;
  return (
    <div className="HeadLine">
      <p>{props.page}</p>
    </div>
  );
};

export default HeadLine;
